  <template>

  <div>

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <b-col
          xl="3"
          cols="12"
          v-if="negociacoes.length > 0 && !carregamentoApi"
        >

          <b-card no-body>
            <b-card-header>
              <p class="h5">Filtrar por</p>
            </b-card-header>
            <b-card-body>
              <!-- Status -->
              <b-form-group
                label="Status"
                label-for="negociacaoStatus"
              >
                <validation-provider
                  #default="{ errors }"
                  name="negociacaoStatus"
                >
                  <v-select
                    v-model="negociacaoStatusSelecionado"
                    label="nome"
                    :options="statusNegociacao"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </b-form-group>
              <!-- Status -->
              <b-button
                variant="primary"
                class="btn-block"
                style="border-radius: 0.358rem !important;"
                @click="filtrarPesquisa()"
              >
                Filtrar
              </b-button>
            </b-card-body>
          </b-card>

        </b-col>

        <b-col
          :xl="!negociacoes.length && !carregamentoApi ? 12 : 9"
          cols="12"
          :style="!negociacoes.length && !carregamentoApi ? '' : 'padding: 0px;'"
        >

        <div
          v-if="!negociacoes.length && filtrar == 0 && !carregamentoApi"
        >

          <b-col
            cols="12"
            :style="!negociacoes.length && filtrar == 0 && !carregamentoApi ? 'padding: 0px;' : 'padding: 0px;'"
          >
            <b-card
              :no-body="true"
              class="text-left p-3"
              style="overflow: hidden;"
            >
              
              <b-card-body>

                <div style="position: absolute; top: 0; right: 0;z-index:0;">
                  <img src="@/assets/images/bg/bg.png" alt="Ícone Favoritos" />
                </div>

                <div style="position: relative;z-index:2;">
                  <b-card-title class="text-white">
                    <h1 class="content-header-title">Bem-vindo às suas <b>negociações</b></h1>
                  </b-card-title>
                  <b-card-text class="my-2">
                    <h6>
                      <b>Aqui é onde você encontrará todas as suas negociações e propostas recebidas e iniciadas.</b>
                      <br>Com a seção de negociações e propostas de hits, você pode manter todas as suas interações em um só lugar.         
                    </h6>
                  </b-card-text>

                </div>

              </b-card-body>

            </b-card>
          </b-col>

          </div>

          <b-col
            v-for="negociacao in negociacoes"
            cols="12"
            xl="12"
          >

            <b-link
              :to="{ name: 'negociacao-visualizar', params: { id: negociacao.id } }"
            >
              <b-card
                class="card-transaction mb-1"
                no-body
              >

                <b-card-body no-body>
                  <div
                    class="transaction-item"
                  >
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          rounded
                          size="42"
                          :variant="negociacaoStatusCor(negociacao.statusNegociacaoValor)"
                        >
                          <feather-icon
                            size="18"
                            :icon="negociacaoStatusIcone(negociacao.statusNegociacaoValor)"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <small class="text-dark">#{{ negociacao.id }}</small>
                        <h6 class="transaction-title">
                          {{ negociacao.hitNomeComposicao }}
                        </h6>
                      </b-media-body>
                    </b-media>
                    <div
                      class="font-weight-bolder"
                      :class="'text-success'"
                    >

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :to="{ name: 'negociacao-visualizar', params: { id: negociacao.id } }"
                      >
                        <feather-icon
                          icon="EyeIcon"
                        />
                      </b-button>

                    </div>
                  </div>
                </b-card-body>

              </b-card>
            </b-link>

          </b-col>

          <infinite-loading
            ref="infiniteLoading"
            :infinite-scroll-disabled="carregamentoApi"
            @infinite="negociacoesLista"
            :identifier="filtrar"
            no-results="noResults"
          >

            <span slot="spinner">
              <img
                src="@/assets/images/logo/logo-loading.gif"
                width="40px"
              >
              <p>Carregando negociações...</p>
            </span>
            <span slot="no-more"></span>
            <span slot="loading">Carregando negociações...</span>

            <div slot="no-results">

              <div
                v-if="filtrar > 0"
              >
                <b-card>
                  <b-card-body>
                    <p class="small mb-0 pb-0">Filtrado por</p>
                    <b-badge
                      variant="primary"
                      class="mb-1"
                    >
                      {{ getStatusNome(negociacaoStatusSelecionadoFinal) }}
                    </b-badge>
                    <h4>Não encontramos negociações com esse filtro!</h4>
                    <p>Filtre novamente para encontrar uma negociação.</p>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="ml-1"
                      @click="limparFiltro()"
                    >
                      <feather-icon
                        size="13"
                        icon="FilterIcon"
                        cursor="pointer"
                      />
                      Limpar Filtro
                    </b-button>
                  </b-card-body>
                </b-card>
              </div>

            </div>

          </infinite-loading>

        </b-col>

      </b-row>

    </section>

  </div>
</template>

<script>
import {
  BLink, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormGroup, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import axios from 'axios'

import InfiniteLoading from 'vue-infinite-loading'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    InfiniteLoading,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  data() {
    return {

      negociacoes: [],
      statusNegociacao: [
        {nome: 'Em negociação', id: 0},
        {nome: 'Aceita', id: 12},
        {nome: 'Recusada', id: 34},
        {nome: 'Vendida', id: 5},
        {nome: 'Cancelada', id: 6},

      ],
      negociacaoStatusSelecionado: null,
      negociacaoStatusSelecionadoFinal: null,
      carregamentoApi: false,

      page: 0,
      size: 10,
      sort: 'DESC',

      filtrar: 0,

    }
  },
  mounted() {
    this.carregarFiltrosLocalStorage();
    this.$router.beforeEach((to, from, next) => {
      if (this.filtrar > 0 && this.negociacoes.length === 0) {
        localStorage.removeItem('negociacaoStatusSelecionadoFinal')
        localStorage.removeItem('negociacaoStatusSelecionado')
      } else {
        this.salvarFiltrosLocalStorage()
      }
      next();
    });
  },
  watch: {
    negociacaoStatusSelecionado: {
      handler(newVal, oldVal) {
        
        switch (newVal) {
          case 12:
            this.negociacaoStatusSelecionadoFinal = [1, 2];
            break;
          case 34:
            this.negociacaoStatusSelecionadoFinal = [3, 4];
            break;
          case 5:
            this.negociacaoStatusSelecionadoFinal = [7];
            break;
          default:
            this.negociacaoStatusSelecionadoFinal = newVal;
            break;
        }
        
        // this.page = 0
        // this.negociacoes = []
        // this.filtrar += 1
        // this.negociacoesLista();
        //this.salvarFiltrosLocalStorage()
      },
      immediate: true
    }
  },
  methods: {

    getStatusNome(statusId) {
      const status = this.statusNegociacao.find((item) => item.id === statusId);
      return status ? status.nome : '';
    },

    salvarFiltrosLocalStorage() {
      localStorage.setItem('negociacaoStatusSelecionadoFinal', JSON.stringify(this.negociacaoStatusSelecionadoFinal))
      localStorage.setItem('negociacaoStatusSelecionado', JSON.stringify(this.negociacaoStatusSelecionado))
    },

    carregarFiltrosLocalStorage() {

      if (this.$route.path === '/negociacoes/vendidas') {

        this.negociacaoStatusSelecionadoFinal = 5
        this.negociacaoStatusSelecionado = 5
        this.page = 0
        this.negociacoes = []
        this.filtrar += 1

      } else {

        const statusSelecionadoFinal = localStorage.getItem('negociacaoStatusSelecionadoFinal');
        if (statusSelecionadoFinal) {
          this.negociacaoStatusSelecionadoFinal = JSON.parse(statusSelecionadoFinal)
        }

        const statusSelecionado = localStorage.getItem('negociacaoStatusSelecionado')
        if (statusSelecionado) {
          this.negociacaoStatusSelecionado = JSON.parse(statusSelecionado)
        }

      }

    },

    // FILTRAR PESQUISA
    filtrarPesquisa() {
      this.page = 0
      this.negociacoes = []
      this.filtrar += 1
    },
    // FILTRAR PESQUISA

    limparFiltro() {

      this.negociacoes = []

      this.negociacaoStatusSelecionado = null
      this.negociacaoStatusSelecionadoFinal = null

      this.page = 0

      this.filtrar = 0

    },

    negociacaoStatusCor(status) {
      if (status == '1' || status == '2' || status == '5' || status == '6') return 'light-secondary'
      if (status == '7') return 'light-success'
      if (status == '3' || status == '4' || status == '8') return 'light-danger'
      return 'light-secondary'
    },

    negociacaoStatusIcone(status) {
      if (status == '1' || status == '2' || status == '5' || status == '6') return 'MessageSquareIcon'
      if (status == '7') return 'CheckIcon'
      if (status == '3' || status == '4' || status == '8') return 'DeleteIcon'
      return 'MessageSquareIcon'
    },

    async negociacoesLista(infiniteLoading) {
      this.carregamentoApi = true

      useJwt.propostasLista({
        page: this.page,
        size: this.size,
        sort: this.sort,
        listaStatusNegociacao: this.negociacaoStatusSelecionadoFinal
      })
        .then(response => {
       
          if (response.data.content.length) {
            this.page += 1
            this.negociacoes.push(...response.data.content)
            infiniteLoading && infiniteLoading.loaded()
          } else {
            infiniteLoading && infiniteLoading.complete()
          }

        })
        .catch(error => {
          console.log(error)
          infiniteLoading && infiniteLoading.complete()
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },


  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

</style>
